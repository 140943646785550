import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { toast } from "react-toastify";
import Modal from "@material-ui/core/Modal";
import { smallbusiness, endPoint } from "../../../src/config/config";
import history from "./../../history";
import { localStorageData } from "../../services/auth/localStorageData";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Cookies from "js-cookie";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 340,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

export default function SimpleModal(props) {
  const dispatch = useDispatch();
  const { smallbusinessPass } = useSelector((state) => state.userRole);
  const { businessName, currentRole } = useSelector((state) => state.userRole);
  const [businessData, setBusinessData] = useState("");
  const [inputPass, setInputPass] = useState("");
  const [modalOpen, setModalOpen] = useState(false);

  const classes = useStyles();
  const [modalStyle] = useState(getModalStyle);
  const location = useLocation();

  useEffect(() => {
    fetchBusiness();
    console.log("s=>", businessData);
    // Check if the current route is "/smallbusiness"
    if (location.pathname === "/smallbusiness") {
      if (localStorageData("_id")) {
        // Check if the cookie is present
        if (!Cookies.get("authenticated")) {
          if (businessData !== "sellerNotExist") {
            setModalOpen(true);
          }
        }
      }
    }
  }, [location.pathname]);

  const fetchBusiness = async () => {
    try {
      const response = await fetch(
        `${endPoint}/isSellerExist/${localStorageData("_id")}`
      );
      const result = await response.json();
      setBusinessData(result);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleClose = () => {
    dispatch({
      type: "ChangesmallbusinessPass",
      payload: false,
    });
    // Modal will remain open unless authenticated
    setModalOpen(true);
  };

  const handleConfirm = async () => {
    const bcrypt = require("bcryptjs");

    if (await bcrypt.compare(inputPass, localStorageData("pass"))) {
      dispatch({
        type: "ChangeCurrentRole",
        payload: smallbusiness,
      });

      // Set a cookie that expires in 15 minutes
      Cookies.set("authenticated", "true", { expires: 0.25 });

      setModalOpen(false); // Close the modal
    } else {
      toast.error("Wrong Password");
    }
  };

  const body = (
    <div style={modalStyle} className={classes.paper}>
      <div className="flex content-center my-4 items-center justify-center">
        <label className="w-40">Password:</label>
        <input
          type="password"
          name="Password"
          id="businessName"
          onChange={(e) => setInputPass(e.target.value)}
          className="input-styl mx-2 h-12 px-2 py-2 w-20"
          placeholder="Password"
        />
        <button className="btn-styl h-10" type="submit" onClick={handleConfirm}>
          Ok
        </button>
      </div>
    </div>
  );

  return (
    <div>
      <Modal
        open={modalOpen}
        onClose={() => {}} // Do nothing on backdrop click or escape
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        BackdropProps={{
          style: { backgroundColor: "rgba(0,0,0,0.5)" }, // Optional: Customize backdrop style
        }}
        disableEscapeKeyDown // Prevent closing on escape key press
      >
        {body}
      </Modal>
    </div>
  );
}
