const ListingColor = [
  "Alizarin",
  "Amaranth",
  "Amber",
  "Amethyst",
  "Apricot",
  "Aqua",
  "Aquamarine",
  "Asparagus",
  "Auburn",
  "Azure",
  "Beige",
  "Bistre",
  "Black",
  "Blue",
  "BlueGreen",
  "BlueViolet",
  "BondiBlue",
  "Brass",
  "Bronze",
  "Brown",
  "Buff",
  "Burgundy",
  "BurntOrange",
  "BurntSienna",
  "BurntUmber",
  "CamouflageGreen",
  "CaputMortuum",
  "Cardinal",
  "Carmine",
  "Carrotorange",
  "Celadon",
  "Cerise",
  "Cerulean",
  "Champagne",
  "Charcoal",
  "Chartreuse",
  "CherryBlossomPink",
  "Chestnut",
  "Chocolate",
  "Cinnabar",
  "Cinnamon",
  "Cobalt",
  "Copper",
  "Coral",
  "Corn",
  "Cornflower",
  "Cream",
  "Crimson",
  "Cyan",
  "Dandelion",
  "Denim",
  "Ecru",
  "Emerald",
  "Eggplant",
  "Falured",
  "Ferngreen",
  "Firebrick",
  "Flax",
  "Forestgreen",
  "FrenchRose",
  "Fuchsia",
  "Gamboge",
  "Gold",
  "Goldenrod",
  "Green",
  "Grey",
  "HanPurple",
  "Harlequin",
  "Heliotrope",
  "HollywoodCerise",
  "Indigo",
  "Ivory",
  "Jade",
  "Kellygreen",
  "Khaki",
  "Lavender",
  "Lawngreen",
  "Lemon",
  "Lemonchiffon",
  "Lilac",
  "Lime",
  "Limegreen",
  "Linen’sw",
  "Magenta",
  "Magnolia",
  "Malachite",
  "Maroon",
  "Mauve",
  "MidnightBlue",
  "Mintgreen",
  "Mistyrose",
  "Mossgreen",
  "Mustard",
  "Myrtle",
  "Navajowhite",
  "NavyBlue",
  "Ochre",
  "Officegreen",
  "Olive",
  "Olivine",
  "Orange",
  "Orchid",
  "Ochre",
  "Officegreen",
  "Olive",
  "Olivine",
  "Orange",
  "Orchid",
  "Papayawhip",
  "Peach",
  "Pear",
  "Periwinkle",
  "Persimmon",
  "PineGreen",
  "Pink",
  "Platinum",
  "Plum",
  "Powderblue",
  "Puce",
  "Prussianblue",
  "Psychedelicpurple",
  "Pumpkin",
  "Purple",
  "Rawumber",
  "Razzmatazz",
  "Red",
  "Robineggblue",
  "Rose",
  "Royalblue",
  "Royalpurple",
  "Ruby",
  "Russet",
  "Rust",
  "Safetyorange",
  "Saffron",
  "Salmon",
  "Sandybrown",
  "Sangria",
  "Sapphire",
  "Scarlet",
  "Schoolbusyellow",
  "SeaGreen",
  "Seashell",
  "Sepia",
  "Shamrockgreen",
  "ShockingPink",
  "Silver",
  "SkyBlue",
  "Slategrey",
  "Smalt",
  "Springbud",
  "Springgreen",
  "Steelblue",
  "Tan",
  "Tangerine",
  "Taupe",
  "Teal",
  "Tenné(Tawny)",
  "Terracotta",
  "Thistle",
  "TitaniumWhite",
  "Tomato",
  "Turquoise",
  "Tyrianpurple",
  "Ultramarine",
  "VanDykeBrown",
  "Vermilion",
  "Violet",
  "Viridian",
  "Wheat",
  "White",
  "Wisteria",
  "Xanthic",
  "Yellow",
  "Zucchini",
  "Honey",
];
export default ListingColor;
