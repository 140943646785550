import React from "react";
import Typography from "@material-ui/core/Typography";
import history from "../../../history";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";

function Aboutus() {
  return (
    <div className=" my-20">
      <Container>
        <br />

        <div className="text-black font-bold text-center text-lg mt-8 mb-4">
          ouiiwe
        </div>
        <p className="text-black text-center text">
          At ouiiwe, our goal is to make it easier for more shoppers to
          discover, shop, and support small businesses.
        </p>

        <div className="text-black font-bold text-center text-lg mt-8 mb-4">
          ouiiwe (the platform)
        </div>
        <p className="text-black text-center text">
          Are you tired of the online noise, tired of the gurus and never-ending
          changing social media algorithms, or simply tired of paying massive
          fees online? We feel your pain, and ouiiwe is here to help. Created by
          other small business owners, we offer a minimal, cost-efficient, and
          simple platform, making it super easy for small businesses to sell
          online and connect with customers.
        </p>

        <div className="text-black font-bold text-center text-lg mt-8 mb-4">
          Our name
        </div>
        <p className="text-black text-center text">
          ouiiwe: /wewe/ <br />
          The name ouliwe is inspired by what we about simplicity, togetherness,
          empowerment and fairness. The name isn't only fun and catchy but
          timeless and futuristic
        </p>

        <div className="text-black font-bold text-center text-lg mt-8 mb-4">
          How is the platform different
        </div>

        <div className="text-black font-bold text-lg mt-8 mb-4">
          Cut out the noise
        </div>
        <p className="text-black text-center text">
          The online environment is becoming too busy, making it harder for
          small businesses to start out. At ouiiwe, we aim to create a one-stop,
          go-to platform for everything small businesses, which will make it
          easier for more shoppers to discover small businesses
        </p>

        <div className="text-black font-bold text-lg mt-8 mb-4">Minimal</div>
        <p className="text-black text">Simple and straight to the point.</p>

        <div className="text-black font-bold text-lg mt-8 mb-4">
          Share story
        </div>
        <p className="text-black  text">
          Your story is one of your biggest selling points as a business. Our
          platform allows you to share your story, which can be read by all
          shoppers who visit your shop, making it easier for you to reach and
          sell to customers who share your core beliefs.
        </p>

        <div className="text-black font-bold text-lg mt-8 mb-4">
          Less overheads
        </div>
        <p className="text-black text text">
          ouiiwe not only takes the stress of owning a website away but also
          charges you zero sign-up fees, zero listing fees, and zero
          subscription fees. Just a 5% commission fee per order processed.
        </p>

        <div className="text-black font-bold text-lg mt-8 mb-4">
          Save Time.{" "}
        </div>
        <p className="text-black text text">
          Simply sign up and start listing, manage your orders, access customer
          insights, with zero limits on listings. Sell anytime, anywhere. We
          make it easier for you to concentrate on what really matters, which is
          focusing on improving your products and services.Listing your products
          on Ouiiwe is as simple as uploading an Instagram post. We have made it
          super easy to track, list, and manage your products
        </p>

        <div className="text-black font-bold text-center text-lg mt-8 mb-4">
          How to start selling:
        </div>

        <div className="text-center leading-8	">
          <span className="font-bold"> 1: </span> Create an account.
        </div>

        <div className=" text-center leading-8	">
          <span className="font-bold"> 2: </span> Register your business.
        </div>
        {/* <div className='center-styl leading-8	'>
          <ul className='list-disc '>
            <li>
              Sign into your shopper account and in the sidebar, click on 'Small
              Business'.
            </li>
            <li>Fill out the business application form</li>
          </ul>
        </div> */}

        <div className="text-center leading-8	">
          <span className="font-bold"> 3: </span> Start listing your products
        </div>

        {/* <div className="text-center leading-8	">
          <span className="font-bold">Step 4: </span> ignin, set up your
          business page and you're ready to go!
        </div> */}

        <div className="center-styl mt-6">
          <a href="/signup" className=" underline">
            Signup
          </a>
        </div>

        <div className="text-black font-bold text-lg mt-8 mb-4">
          Our founder{" "}
        </div>
        <p className="text-black text-center text">
          Patrick Luwemba, “I love small businesses and the unique products they
          offer. My goal in creating ouliwe is to help more people discover and
          fall in love with them.”
        </p>

        {/* <Grid item xs={12}>
            <div className='text-black font-bold  text-lg'>Our fees</div>
            <p className='text-black text-center'>
              Zero sign up fees.
              <br />
              zero listing fees.
              <br />
              zero monthly fees
              <br />
              zero advertising fees.
              <br />
              5% will be deducted from the total payable amount to the small
              business.
              <br />
              $1.99 service fee will be added to shopper’s total check-out fee
            </p>
          </Grid> */}

        <button
          onClick={() => history.push("/howitworks")}
          className="text-black underline text-lg font-bold  text-center float-right"
        >
          How it works
        </button>
      </Container>
    </div>
  );
}

export default Aboutus;
